<template>
  <div class="bgc-white p-20 bd" id="mainContent">
    <h4 class="c-grey-900">تعديل موظف</h4>
    <div class="mT-30">
      <form @submit="checkForm">
        <div class="form-row mT-20">
          <div class="form-group col-md-6">
            <label for="inputEmail4">{{ $t("employeeName") }}</label
            ><input type="name" class="form-control" id="inputEmail4" v-model="supervisorName" required/>
          </div>
          <div class="form-group col-md-6">
            <label for="inputPassword4">{{ $t("phone") }}</label
            ><input  type="tel" pattern="[0]{1}[1|9]{1}[0-9]{8}" placeholder="09xxxxxxxx" class="form-control" id="inputPassword4" v-model="supervisorPhone" required/>
          </div>
        </div>
        <div class="form-row mT-20">
          <div class="form-group col-md-6">
            <label for="inputState">نوع الموظف</label
            ><select id="inputState" class="form-control" v-model="supervisorTypeId" required>
              <option value="1" selected="selected">مدخل اسئلة</option>
              <option value="2">محاسب</option>
              <option value="3">مشرف</option>
              <option value="4">مدير</option>
            </select>
          </div>
        </div>
        <div class="form-row mT-20">
          <loading v-show="loading" />
        <sui-button
          type="submit"
          positive
          class="btn btn-primary mL-10"
          content="تعديل"
          icon="check"
          label-position="left"
          :disabled="loading"
          v-show="!loading"
        />
        <sui-button
          type="button"
          :content="$t('cancel')"
          :disabled="loading"
          v-show="!loading"
          @click="$emit('closePanel', {})"
        />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    supervisor:{},
    reload: { type: Function },
  },
   created() {
     this.supervisorName = this.supervisor.name;
     this.supervisorPhone = "0"+this.supervisor.user.phone.substr(4, 11);
     this.supervisorTypeId = this.supervisor.supervisor_type_id;
     this.supervisorId = this.supervisor.id;
   },
  data() {
    return {
      supervisorName: "",
      supervisorPhone: "",
      supervisorTypeId: -1,
      supervisorId:-1,
      loading: false
    }
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      this.loading = true;
      this.addSupervisor();
    },
    addSupervisor: function () {
      let headers = {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      };
      this.$http
        .patch(
          "https://yalla-emtihan.com/backend/supervisors/editSupervisor",
          {
            supervisor_name: this.supervisorName,
            supervisor_phone: "+249" + this.supervisorPhone.substr(1, 11),
            supervisor_type_id: this.supervisorTypeId,
            supervisor_id: this.supervisorId
          },
          { headers }
        )

        .then(
          function (data) {
            this.loading = false;
            switch (data.status) {
              case 200:
                this.$emit("closePanel", {});
                this.success(this.$t("success"));
                this.reload();
                break;
            } 
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
            }
          }
        );
    },
    error: function (message) {
      this.$toast.error(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    success: function () {
      this.$toast.success(this.$t("success"), {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>

<style scoped>
#mainContent {
  text-align: right;
  direction: rtl;
}

</style>